<template>
  <bg-page>
    <div class="Box PageDevelopmentBox">
      <el-image
          :src="require('../Home/img/logo_Notext.png')"
          fit="cover"
          style="width: 300px; height: auto;background-color: transparent;">
        <template #error>
          <div class="image-slot">
            <span>加载中</span>
          </div>
        </template>
      </el-image>
      <div>
        <p>网站试运行，功能建设中</p>
      </div>
    </div>
  </bg-page>
</template>

<script>
import { useStore }  from "vuex"
import { useRoute }  from "vue-router"
import * as Vue from "vue";
import router from "@/router";
export default {
  name: "PageDevelopment",
  components: {},
  setup(props, { attrs, slots, emit }) {
    const store = useStore()
    const route = useRoute()
    const state = Vue.reactive({})
    return {
      state,
      props
    }
  }
}
</script>

<style lang="scss" scoped>
.PageDevelopmentBox{
  min-height: calc(100vh - 200px);
  text-align: center;
  >div:nth-child(1){
    margin-top: 100px;
  }
  p{
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    //font-weight: 600;
    color: #7F0404;
    margin-top: 30px;
    margin-left: 70px;
    letter-spacing: 25px;
  }
}
</style>